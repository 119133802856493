<template>
  <div>
    <van-nav-bar left-arrow @click-left="onClickLeft" :border="false" />
    <div class="home">
      <h2>支付成功</h2>
      <img class="success" src="../assets/img/pay02.png" alt="" />
      <h3>入场前需扫码验证</h3>
      <img class="verity" :src="codeUrl" alt="" />
      <div class="double-btn">
        <div @click="checkDetail">查看详情</div>
        <div @click="backIndex">返回首页</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCode } from "@/api/activity";
export default {
  name: "Home",
  components: {},
  data() {
    return {
      active: 0,
      codeUrl: "",
      rec_id: null
    };
  },
  created() {
    this.rec_id = this.$route.query.rec_id;
    this.getentreCode(this.$route.query.rec_id);
  },
  methods: {
    onClickLeft() {
      this.$router.back();
    },
    async getentreCode(rec_id) {
      const res = await getCode({ rec_id });
      this.codeUrl = res.data.data.saveDir;
      console.log(res.data.data.saveDir);
    },
    backIndex() {
      this.$router.push("/huodong");
    },

    checkDetail() {
      this.$router.push({
        path: "/check",
        query: {
          rec_id: this.rec_id
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/.van-icon-arrow-left::before {
  color: #323233;
}
.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  .success {
    width: 128px;
    height: 128px;
    margin-bottom: 60px;
  }
  .verity {
    width: 303px;
    height: 303px;
    margin-bottom: 50px;
  }
  .double-btn {
    display: flex;
    justify-content: space-between;
    div {
      width: 181px;
      height: 63px;
      border: 1px solid #999999;
      border-radius: 31px;
      text-align: center;
      line-height: 65px;
      font-size: 28px;

      font-weight: 600;
      color: #333333;
      &:nth-child(1) {
        margin-right: 42px;
      }
    }
  }
}
</style>
